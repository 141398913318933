<template>
	<el-dialog title="新增照片" :close-on-click-modal="false" :visible.sync="visible" width="940px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" :inline="true" label-width="110px" class="border-form">
			<el-row class="set_border">
				<el-col :span="8" class="col-set_border">
					<el-form-item prop="subjectNo" label="标的物" class="item">
						<template slot-scope="scope">
							<el-select id="subjectNo" v-model="dataForm.subjectNo" placeholder="请选择" style="width: 160px;">
								<el-option v-for="item in subjectList" :key="item.displayValue" :label="item.displayName"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</template>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item prop="major" label="专业" class="item">
						<template slot-scope="scope">
							<el-select v-model="dataForm.major" placeholder="请选择" style="width: 150px;">
								<el-option v-for="item in majorList" :key="item.displayValue" :label="item.displayName"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</template>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item prop="registerTime" label="登记时间" class="item1">
						<el-date-picker v-model="dataForm.registerTime" type="date" value-format="yyyy-MM-dd"
							placeholder="选择日期" style="width: 150px;">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" id="demo" style="margin-top: 20px;">
					<el-form-item label="" style="width:100%">
						<el-upload ref="uploadImg" class="upload-demo" action="action" :http-request="uploadImg" multiple
							drag :show-file-list=false :auto-upload="true">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							<!-- <div style="color: red;font-weight: 700;line-height: 24px;">* 建议照片尺寸 598px × 449px</div> -->
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="24" style="margin-top: 20px;margin-left: -15px;">
					<el-form-item label=" ">
						<el-table class="dataListTable" border header-align="center"
							v-if="dataForm.photoFilePathList.length > 0" :data="dataForm.photoFilePathList"
							style="width: 100%;" :cell-style="{ padding: '0px' }">
							<el-table-column type="index" label="序号" width="60" header-align="center">
							</el-table-column>
							<el-table-column prop="fileName" label="文件名" width="240" header-align="center">
							</el-table-column>
							<el-table-column label="图片" width="180" header-align="center">
								<template slot-scope="scope">
									<el-image style="height: 100px" :src="scope.row.fileUri"
										:preview-src-list="previewList(scope.row.fileUri)" fit='contain'></el-image>
								</template>
							</el-table-column>
							<el-table-column label="描述" width="300px" header-align="center">
								<template slot-scope="scope">
									<el-input type="textarea" rows="2"
										v-model="dataForm.photoFilePathList[scope.$index].fileContent"
										placeholder="请填写"></el-input>
								</template>
							</el-table-column>
							<el-table-column label=" " width="103">
								<template slot-scope="scope">
									<el-button size="mini" type="danger" @click="delImg(scope.$index)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "qualitySituationList-add-or-update",
	data() {
		return {
			title: '',
			addImg: addImg,
			visible: true,
			action: "",
			form: {},
			subjectList: [],
			majorList: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				recNo: "",
				photoType: "",
				registerTime: "",
				registerMan: "",
				photoContent: '',
				photoFilePathList: [],
				major: '',
				vivivi: ''
			},
			dataRule: {
				registerTime: [
					{ required: true, message: "登记时间不能为空！", trigger: "blur" }
				],
				subjectNo: [
					{ required: true, message: "标的物不能为空！", trigger: "blur" }
				],
				major: [
					{ required: true, message: "专业不能为空！", trigger: "blur" }
				]
			}
		};
	},
	components: {

	},
	activated() {

	},
	mounted() {
		this.getSubjectDropDown()
		this.getMajorList()
	},
	methods: {
		init(photoType, subjectNo, major) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: subjectNo,
				major: major,
				recNo: "",
				photoType: "",
				registerTime: "",
				registerMan: "",
				photoContent: '',
				photoFilePathList: [],
			};
			this.dataForm.photoType = photoType;
			this.dataForm.registerMan = $common.getItem("userName");
			this.title = '新增' + this.getPhotoTypeName();
			this.$nextTick(() => {
				this.visible = true;
			})
		},
		getPhotoTypeName() {
			if (this.dataForm.photoType === '1') {
				return '质量照片';
			} else if (this.dataForm.photoType === '2') {
				return '进度照片';
			}
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
				}
			});
		},
		getMajorList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.majorList = data.body;
				}
			});
		},
		previewList(fileUri) {
			return [fileUri]
		},
		//图片
		delImg(index) {
			this.dataForm.photoFilePathList.splice(index, 1);
		},
		uploadImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			// this.$refs.uploadImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/94/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.photoFilePathList.push({
						filePath: res.body.fileSavePath,
						thumbnail: res.body.thumbnailPath,
						fileUri: res.body.fileUri,
						fileName: res.body.fileName,
						fileContent: this.extractFileName(res.body.fileName)
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		extractFileName(fileName) {
			let index = fileName.lastIndexOf(".");
			return fileName.substr(0, index)
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/qualityprogressphotoinfo/save";
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 718px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.item1::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

.border-form .el-row.set_border .col-set_border {
	border-right: solid #dadada 1px;
}

::v-deep(.upload-demo .el-upload-dragger) {
	width: 880px;
	height: 100px;
	margin-bottom: -30px;
}

::v-deep(.upload-demo .el-upload-dragger .el-icon-upload) {
	font-size: 30px;
	margin: 20px 1px;
	line-height: 20px;
}

::v-deep(.upload-demo .el-upload-dragger .el-upload__text) {
	line-height: 5px;
}
</style>
